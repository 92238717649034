/**
  Make header rules
 */
@mixin header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 18px 0;
}

/**
  Make hamburger button rules
 */
@mixin hamburger {
  height: 22px;
  width: 30px;

  & > span {
    display: block;
    width: 100%;
    height: 2px;
    background: #000;
    margin: 0;
    padding: 0;

    &:nth-of-type(2) {
      margin: 8px 0;
    }
  }
}

/**
  Make rules for mobile header
 */
@mixin header-compact {
  position: relative;

  & > .header-nav {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    transition: transform .3s, opacity .2s;
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;

    &[data-menu="false"] {
      opacity: 0;
      pointer-events: none;
      transform: translateY(-20%);
    }
  }
}


/*
==================================================
    /Large screens
==================================================
*/
@mixin hamburger-lg {
  display: none;
}

@mixin header-expand {
  & > .header-nav {
    position: static;
    top: auto;
    left: auto;
    right: auto;
    display: block;
    opacity: 1 !important;
    pointer-events: all !important;
    transform: none !important;
    box-shadow: none;
  }
}
