@import "../spacing/functions";
@import "../typography/mixins";

@mixin header-nav {
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .1);

  z-index: 10000;
}

@mixin nav-list {
  @include body-fz-2;
  padding: get-spacing(200) 0;
}

@mixin nav-link {
  display: block;
  padding: get-spacing(100) 0;
  @include button;

  position: relative;

  &::after {
    content: " ";
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, .1);

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

/*
==================================================
    /Large screens
==================================================
*/
@mixin nav-list-lg {
  padding: 0;
  display: flex;
}

@mixin nav-link-lg {
  padding-left: 25px;
  padding-right: 25px;

  &::after {
    display: none;
  }
}

@mixin nav-list-item-spaced-lg {
  margin-top: 0;
  margin-left: 25px;
}

@mixin header-nav-lg {
  background: none;
}
