@import "./components/spacing/functions";
@import "./components/typography/mixins";
@import "./components/footer/mixins";
@import "./components/header/mixins";
@import "./components/header-nav/mixins";
@import "./components/alert/mixins";
@import "./components/modal/mixins";

/*
==================================================
    /Layout
==================================================
*/
.contained {
  padding-left: get-contained-side-spacing('lg');
  padding-right: get-contained-side-spacing('lg');
}

.contained-y {
  @include title-fz-2;
}

/*
==================================================
    /Contact page
==================================================
*/
.contact-form {
  width: calc(100% / 12 * 10);
}

.contact-form-wrapper {
  @include title-fz-3;
}

.contact-title {
  @include title-3;
}

/*
==================================================
    /Footer
==================================================
*/
.footer {
  @include footer-lg;
}

.footer-brand-container {
  @include footer-brand-container-lg;
}

.footer-nav {
  @include footer-nav-lg;
}

/*
==================================================
    /Header
==================================================
*/
.header-hamburger {
  @include hamburger-lg;
}

.header {
  @include header-expand;
}

.nav-list {
  @include nav-list-lg;
}

.nav-link {
  @include nav-link-lg;
}

.nav-list-item-spaced {
  @include nav-list-item-spaced-lg;
}

.header-nav {
  @include header-nav-lg;
}

/*
==================================================
    /Typography
==================================================
*/
.title-1 {
  @include title-fz-2;
}

/*
==================================================
    /Home page/Landing
==================================================
*/
.landing-container {
  width: calc(7 / 12 * 100%);
}

.landing {
  & .contained {
    margin: 0 auto;
  }
}

/*
==================================================
    /Home page/Alsace
==================================================
*/
.alsace {
  width: 100%;
}

.alsace-text-container {
  display: flex;

  & > p {
    flex: 1;
    margin-left: 16px;
    margin-right: 16px;
    text-align: justify;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.alsace-button {
  width: calc(3 / 12 * 100%);
}

/*
==================================================
    /Home page/Houses
==================================================
*/
.houses {
  width: calc(10 / 12 * 100%);
}

.card-container {
  display: flex;
  flex-wrap: wrap;
}

.houses-header {
  width: calc(6 / 12 * 100%);
  @include title-fz-2;
  margin: 0 auto get-spacing(200);
}

.card {
  width: 40%;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
}

/*
==================================================
    /Home page/Discover
==================================================
*/
.discover-card {
  width: calc(4 / 12 * 100%);
}

.discover-map {
  height: 41vh;
}

.discover {
  padding-top: 20vh;
}


/*
==================================================
    /House/Details
==================================================
*/
.details {
  width: calc(8 / 12 * 100%);
}

.house-header {
  margin-top: get-spacing(100);
}

.house-title {
  @include title-fz-3;
}

.house-content-wrapper {
  display: flex;
  align-items: center;
  @include title-fz-3;
  margin-bottom: get-spacing(200);
}

.carousel-container {
  width: calc(7 / 12 * 100%);
}

.detail-contained {
  width: calc(5 / 12 * 100%);
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
}

.details {
  width: 100%;
}

.other-houses-body {
  width: 100%;
}

.other-house {
  display: flex;
  align-items: center;
}

.other-houses-header {
  width: 100%;
}

.other-house-picture {
  width: calc(7 / 12 * 100%);
}

.other-house-img {
}

.house-title::after {
  width: 10%;
}

.house-overline {
  @include subtitle-1;
}

.other-house-body {
  width: calc(6 / 12 * 100%);
  box-sizing: border-box;
  margin-top: 0;
  padding-left: calc(.5 / 6 * 100%);
}

.glide__bullets {
  display: block;
}

.glide__bullet {
  position: absolute;
  font-size: 1.5rem;

  &[data-glide-dir="<"] {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 32px;
  }

  &[data-glide-dir=">"] {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 32px;
  }
}

/*
==================================================
    /Alert
==================================================
*/
.alert-container {
  @include alert-container-lg;
}

/*
==================================================
    /Modal
==================================================
*/
.modal {
  @include modal-lg;
}

.modal-title {
  @include title-fz-4;
}

/*
==================================================
    /Login
==================================================
*/
.login {
  width: 40%;
  
}


//.other-house {
//  display: flex;
//  align-items: center;
//}
//
//.other-houses-header, .other-houses-body {
//  width: 100%;
//}
//
//.other-house-img {
//  width: 50%;
//}
//
//.other-house-body {
//  width: 50%;
//  box-sizing: border-box;
//  padding-left: calc(.5 / 6 * 100%);
//  margin-top: 0;
//}
